import React, { Component } from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PinkButton } from "../components/StyledButton";

const BackgroundSection = ({ data, className, children }) => {
  // Set ImageData.
  const imageData = data.heroimage.childImageSharp.fluid;

  return (
    <BackgroundImage Tag="div" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  );
};

const Contact = ({ data }) => {
  const formAction = data.site.siteMetadata.formSubmitURL
  const formFieldURL = data.site.siteMetadata.formURL
  const thankYouPage = data.site.siteMetadata.thankYouPage
  return (
    <Layout
      headerClass="relative bg-white"
      bodyClass="px-0 md:px-0 lg:px-0 h-full"
    >
      <SEO title="Get In Contact" />
      <div className="min-h-screen h-full flex flex-col mb-64 sm:mb-20 md:mb-20 ">
        <BackgroundSection data={data} className="w-full h-40 sm:h-32 lg:h-56">
          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight text-shadow-xl">
                Get In Contact
              </h1>
            </div>
          </div>
        </BackgroundSection>
        <BackgroundImage
          Tag="div"
          fluid={data.bgImage.childImageSharp.fluid}
          className="container mx-auto px-4 mt-5 sm:px-2 md:px-20 lg:px-24 w-full flex flex-col md:flex-row"
        >
          <div
            className="w-full sm:w-3/4 lg:w-1/2 mt-10 px-6 py-4 bg-white"
            style={{
              boxShadow:
                "0 15px 35px rgb(35,78,82,.1), 0 5px 15px rgba(0,0,0,.07)",
            }}
          >
            <div className="grid items-center w-full grid-cols-1 gap-0 mx-auto lg:grid-cols-11 lg:gap-24 xl:w-11/12">
              <div className="col-auto md:col-span-5">
                <form
                  className="mb-6 border-0 rounded-lg shadow-xl card"
                  action={formAction}
                  method="POST"
                >
                  <div className="justify-center pb-0 border-0 text-darkblue card-header">
                    <h2 className="mb-4 text-3xl text-left ">
                      If you have any queries, please reach out to me here:
                    </h2>
                  </div>
                  <div className="px-6 py-4 space-y-4 border-b border-gray-200 card-body focus:ring-pink-600">
                    <label className="flex">
                      <span className="sr-only">First Name</span>
                      <input
                        name="name"
                        className="mt-0 form-input focus:ring-pink-600  focus-within:ring-pink-600 hover:ring-pink-600"
                        type="text"
                        placeholder="Your name"
                        required="true"
                      />
                    </label>
                    <label className="flex">
                      <span className="sr-only">Email Address</span>
                      <input
                        name="email"
                        className="mt-0 form-input"
                        type="email"
                        placeholder="Your e-mail"
                        required="true"
                      />
                    </label>
                    <label className="flex">
                      <span className="sr-only">Phone Number</span>
                      <input
                        name="phone"
                        className="mt-0 form-input"
                        type="phone"
                        placeholder="Your phone number (optional)"
                      />
                    </label>
                    <label className="flex">
                      <span className="sr-only">Your query?</span>
                      <textarea
                        name="query"
                        className="mt-0 form-input"
                        type="textarea"
                        height=""
                        placeholder="Enquiring about coaching for your company/team/personal?"
                        required="true"
                      />
                    </label>
                    <div className="flex flex-row items-center">
                      <PinkButton type="submit">Send your enquiry</PinkButton>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="_next"
                    value={thankYouPage}
                  ></input>
                  <input
                    type="hidden"
                    name="_subject"
                    value="Enquiry from ACExecutiveCoaching.com "
                  ></input>
                  <input
                    type="hidden"
                    name="_autoresponse"
                    value="Thank you for reaching out. I'll be in contact soon. \nKind regards, Angela"
                  ></input>
                  <input
                    type="hidden"
                    name="_replyto"
                    value="Thank you for reaching out. I'll be in contact soon. \nKind regards, Angela"
                  ></input>
                  <input type="hidden" name="_url" value={formFieldURL}></input>
                </form>
                <p className="text-xs text-center text-white">
                  We respect your privacy and do not share your details with
                  anyone.
                </p>
              </div>
            </div>
            <p className="text-3xl">
              or send your enquiry to any of the following:
            </p>
            <div className="flex flex-row p-4">
              <div className="px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                </svg>
              </div>
              <div>
                <a
                  href="mailto:angela@acexecutivecoach.com"
                  className="hover:underline"
                >
                  angela@acexecutivecoach.com
                </a>
              </div>
            </div>
            <div className="flex flex-row p-4">
              <div className="px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                </svg>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/ac-executive-and-business-coaching/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AC Executive and Business Coaching
                </a>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query images {
    heroimage: file(relativePath: { eq: "contact-page-network-color.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgImage: file(
      relativePath: { eq: "curves-of-modern-architechture-black-and-white.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    site {
      siteMetadata {
        formSubmitURL
        formURL
        thankYouPage
      }
    }
  }
`;

export default Contact;
